.wod-map-popup h3 {
  padding: 0 1rem;
  font-family: 'Sigmar One', cursive;
  font-weight: 400;
  font-style: normal;
  font-size: 1.2rem;
  line-height: 0.857;
  color: #1CABAB;
}

.wod-map-popup h3 span {
  display: block;
  padding-top: 0.5rem;
  font-family: 'Satoshi', sans-serif;
  font-weight:  700;
  font-style:   normal;
  font-stretch: normal;
  font-size: 1rem;
  color: #0D4466;
}

.mapboxgl-popup-content {
  padding: 0 !important;
}
