@font-face {
  font-family: 'Satoshi';
  src: url('/fonts/Satoshi-Bold.woff2') format('woff2'),
       url('/fonts/Satoshi-Bold.woff') format('woff');
  font-weight:  700;
  font-style:   normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Satoshi';
  src: url('/fonts/Satoshi-BoldItalic.woff2') format('woff2'),
       url('/fonts/Satoshi-BoldItalic.woff') format('woff');
  font-weight:  700;
  font-style:   italic;
  font-stretch: normal;
}


@font-face {
  font-family: 'Satoshi';
  src: url('/fonts/Satoshi-Regular.woff2') format('woff2'),
       url('/fonts/Satoshi-Regular.woff') format('woff');
  font-weight:  400;
  font-style:   normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Satoshi';
  src: url('/fonts/Satoshi-Italic.woff2') format('woff2'),
       url('/fonts/Satoshi-Italic.woff') format('woff');
  font-weight:  400;
  font-style:   italic;
  font-stretch: normal;
}

@font-face {
    font-family: 'Diary Notes';
    src: url('/fonts/Diary-Notes/diary-notes-webfont.woff2') format('woff2'),
         url('/fonts/Diary-Notes/diary-notes-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
